export enum FeatureFlags {
  SaveWwwcValue = 'SAVE_WWWC_VALUE',
  CropDicomImages = 'CROP_DICOM_IMAGES',
  CreateStudyNoDicom = 'CREATE_STUDY_NO_DICOM_STUDY',
  DisableUndoSignReport = 'DISABLE_UNDO_SIGN_REPORT',
  DisableSendReport = 'DISABLE_SEND_REPORT',
  CloseAfterSignReport = 'CLOSE_AFTER_SIGN_REPORT',
  SyncLayoutMultipleWindows = 'SYNC_LAYOUT_MULTIPLE_WINDOW',
  Viewer7x7 = 'VIEWER_7x7',
  CompareRegionMatrix2x3 = 'COMPARE_REGIONS_MATRIX_2X3',
  CustomerSupportChat = 'CUSTOMER_SUPPORT_CHAT',
  DebtWarning = 'DEBT_WARNING',
  EnableOrganizationSpecificFields = 'ENABLE_ORGANIZATION_SPECIFIC_FIELDS',
  ShowNewStudyEditWebappsPage = 'SHOW_NEW_STUDY_EDIT_WEBAPPS_PAGE',
  ShowStudyStatisticsMetrics = 'SHOW_STUDY_STATISTICS_METRICS',
  EnableNewViewerOption = 'ENABLE_NEW_VIEWER_OPTION',
  PdfEmbeddedViewer = 'PDF_EMBEDDED_VIEWER',
  IncludeReferringAssigned = 'INCLUDE_REFERRING_ASSIGNED',
  EnableNewWorklistSection = 'ENABLE_NEW_WORKLIST_SECTION',
  EnableNewRecordAPI = 'ENABLE_NEW_RECORD_API',
  EnableAutomaticUpdatesWorklist = 'ENABLE_AUTOMATIC_UPDATES_WORKLIST',
  HideQRCodeSending = 'HIDE_QR_CODE_SENDING',
  EnablePractitionerReassignment = 'ENABLE_PRACTITIONER_REASSIGNMENT',
  BlockSendPROA = 'BLOCK_SEND_PROA',
  EnforceOverlayTool = 'ENFORCE_OVERLAY_TOOL',
}
